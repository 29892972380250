import React, { useState } from "react";
import { useMutation } from "react-query";
import UniversalModal from "./UniversalModal";
import { InputField } from "../inputs/TextField";
import { PrimaryButton } from "../buttons/primaryButton/PrimaryButton";
import { InputError } from "../../helpers/inputValidation/InputError";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import allValid from "../../helpers/inputValidation/validators/allValid";
import { ReactComponent as Xicon } from "../../assets/X-ico.svg";
import {
    createClient,
    editClient
} from "../../services/clients";
import { SecondaryButton } from "../buttons/secondaryButton/SecondaryButton";
import {
  StyledContainer,
  StyledHeaderContainer,
  StyledHeaderTitle,
  StyledModalFieldsDiv,
} from "./CreateEditServiceModal.styles";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import {
  StyledFormButtonsWrapper,
  StyledFormFieldsContainer,
} from "./CreateGroupModal.styles";
import { StyledXButton } from "../calendar/reservationModal/ReservationModal.styles";
import { IClient } from "../dataGridTable/ClientsTable";

interface ChangedValues {
  id: number;
  petName?: string;
  ownerName?: string;
  phoneNumber?: string;
  note?: string;
}

interface CreateGroupModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  client?: IClient;
}

const CreateEditClientModal = ({
  isModalOpen,
  handleClose,
  client,
}: CreateGroupModalProps) => {
  const { showNotification } = useNotificationContext();

  const [petName, setPetName] = useState<string>(
    client?.petName ?? ""
  );
  const [ownerName, setOwnerName] = useState<string>(
    client?.ownerName ?? ""
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    client?.phoneNumber ?? ""
  );
  const [note,setNote] = useState<string>(
    client?.note ?? ""
  );

  const [petNameError, setPetNameError] = useState<InputError | null>(null);
  const [ownerNameError, setOwnerNameError] = useState<InputError | null>(null);
  const [phoneNumberError,setPhoneNumberError] = useState<InputError | null>(null);

  const { mutate: createClientMutation } = useMutation(createClient, {
    onSuccess: () => {
      handleClose();
      showNotification("Klijent uspesno dodat", Severity.Success);
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 409) {
        showNotification("Klijent sa ovim kredencijalima već postoji.", Severity.Error);
      } else {
        showNotification("Greška prilikom kreiranja klijenta", Severity.Error);
      }
    },
  });
  

  const { mutate: editClientMutation } = useMutation(editClient, {
    onSuccess: () => {
      handleClose();
      showNotification("Klijent uspesno izmenjen", Severity.Success);
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 409) {
        showNotification("Klijent sa ovim kredencijalima već postoji.", Severity.Error);
      } else {
        showNotification("Greška prilikom menjanja klijenta", Severity.Error);
      }
    },
  });

  const validateFormFields = (): boolean => {
    const validated = {
      petName: isFilledString(petName, InputError.EmptyClientPetName),
      ownerName: isFilledString(ownerName, InputError.EmptyClientOwnerName),
      phoneNumber: isFilledString(phoneNumber, InputError.EmptyClientPhoneNumber)
    };
    if (!allValid(validated)) {
      setPetNameError(validated.petName.isValid ? null : validated.petName.error);
      setOwnerNameError(validated.ownerName.isValid ? null : validated.ownerName.error);
      setPhoneNumberError(validated.phoneNumber.isValid ? null : validated.phoneNumber.error);
      return false;
    }
    setPetNameError(null);
    setOwnerNameError(null);
    setPhoneNumberError(null);
    return true;
  };

  const areValuesChanged = (): boolean => {
    return (
      client?.petName !== petName ||
      client?.ownerName !== ownerName ||
      client?.phoneNumber !== phoneNumber ||
      client?.note !== note
    );
  };

  const generateChangedValues = (): ChangedValues => {
    const changedValues: ChangedValues = {
      id: client ? client.id : -1,
    };

    if (client?.petName !== petName) {
      changedValues.petName = petName;
    }

    if (client?.ownerName !== ownerName) {
        changedValues.ownerName = ownerName;
    }

    if (client?.phoneNumber !== phoneNumber) {
        changedValues.phoneNumber = phoneNumber;
    }

    if (client?.note !== note) {
        changedValues.note = note;
    }

    return changedValues;
  };

  const handleAddNewClient = () => {
    if (validateFormFields()) {
      if (client === undefined) {
        const newClientInfo = {
          petName: petName,
          ownerName: ownerName,
          phoneNumber: phoneNumber,
          note: note
        };

        createClientMutation(newClientInfo);

        setPetName("");
        setOwnerName("");
        setPhoneNumber("");
        setNote("");
        return;
      }
      if (!areValuesChanged()) {
        showNotification("Nema promena", Severity.Info);
        return;
      }
      editClientMutation(generateChangedValues());
    }
  };

  return (
    <UniversalModal
      width={
        window.innerWidth >= 900
          ? "34.375rem"
          : window.innerWidth >= 600
          ? "75%"
          : "90%"
      }
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledFormFieldsContainer>
        <StyledContainer>
          <StyledHeaderContainer>
            {client ? (
              <StyledHeaderTitle>Izmena klijenta</StyledHeaderTitle>
            ) : (
              <StyledHeaderTitle>Dodavanje novog klijenta</StyledHeaderTitle>
            )}
            <StyledXButton onClick={handleClose}>
              <Xicon />
            </StyledXButton>
          </StyledHeaderContainer>
          <StyledModalFieldsDiv>
            <InputField
              label={Boolean(petNameError) ? petNameError : "Ime ljubimca"}
              placeholder="Ime ljubimca"
              value={petName}
              onChange={(event) => setPetName(event.target.value)}
              error={Boolean(petNameError)}
              onFocus={() => setPetNameError(null)}
            />
            <InputField
              label={Boolean(ownerNameError) ? ownerNameError : "Ime vlasnika"}
              placeholder="Ime vlasnika"
              value={ownerName}
              onChange={(event) => setOwnerName(event.target.value)}
              error={Boolean(ownerNameError)}
              onFocus={() => setOwnerNameError(null)}
            />
            <InputField
              label={Boolean(phoneNumberError) ? phoneNumberError : "Broj telefona"}
              placeholder="Broj telefona"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              error={Boolean(phoneNumberError)}
              onFocus={() => setPhoneNumberError(null)}
            />
            <InputField
              label="Opis"
              multiline
              rows={4}
              placeholder="Opis"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </StyledModalFieldsDiv>
          <StyledFormButtonsWrapper>
            <PrimaryButton
              text={"Sačuvaj"}
              width={"100%"}
              onClick={handleAddNewClient}
            />
            <SecondaryButton
              text={"Otkaži"}
              width={"100%"}
              onClick={handleClose}
            />
          </StyledFormButtonsWrapper>
        </StyledContainer>
      </StyledFormFieldsContainer>
    </UniversalModal>
  );
};

export default CreateEditClientModal;
