import apiClient from "./api/api";
import { CITIES, CITY_PARTS, COUNTRIES, GENERAL_INFO, IMAGES } from "./routes/routes";

export const getCountries = async () => {
  try {
    const response = await apiClient.get(COUNTRIES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCities = async (countryId?: number ) => {
  try {
    const endpoint = countryId ? `${CITIES}/${countryId}` : CITIES;
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCityParts = async (cityId: number) => {
  try {
    const response = await apiClient.get(CITY_PARTS(cityId));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSalonGeneralInfo = async ({
  name,
  address,
  cityId,
  cityPartId,
  countryId,
  mobilePhone,
  pib,
  coverImage,
  isPaymentCardAllowed
}: {
  name: string;
  address: string;
  cityId: number;
  cityPartId: number;
  countryId: number;
  mobilePhone: string;
  pib: string;
  coverImage: string;
  isPaymentCardAllowed: boolean
}) => {
  const requestData = {
    name,
    address,
    cityId,
    cityPartId,
    countryId,
    mobilePhone,
    pib,
    coverImage,
    isPaymentCardAllowed
  };

  const { data } = await apiClient.post(GENERAL_INFO, requestData);
  return data;
};

export const addSalonImages = async ({
  description,
  images,
}: {
  description?: string;
  images: string[];
}) => {
  const requestData = {
    description,
    images,
  };

  const { data } = await apiClient.post(IMAGES, requestData);
  return data;
};
