import { useQuery } from "react-query";
import apiClient from "./api/api";
import { SALONS_CLIENTS_KEY } from "../constants/queryKeys";
import { CLIENTS_SEARCH, PREFIX_CLIENTS } from "./routes/routes"

const getClients = async () => {
    const { data } = await apiClient.get(PREFIX_CLIENTS);
    return data;
};
  
export const useClients = (enabled = true) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
      [SALONS_CLIENTS_KEY],
      () => getClients(),
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: false,
        enabled,
      }
    );
  
    return {
      data: data?.result ?? [],
      isLoading,
      isError,
      isFetching,
      refetch,
    };
};

export const editClient = async (clientInfo: any) => {
  const { data } = await apiClient.put(`${PREFIX_CLIENTS}/${clientInfo.id}`, clientInfo);
  return data;
};

export const createClient = async (clientInfo: any) => {
  const { data } = await apiClient.post(PREFIX_CLIENTS, clientInfo);
  return data;
};

export const deleteClient = async (clientId: number) => {
  const { data } = await apiClient.delete(`${PREFIX_CLIENTS}/${clientId}`);
  return data;
};

export const searchClients = async ({searchText} : {searchText : string}) => {
  const { data } = await apiClient.post(CLIENTS_SEARCH, {searchText})
  return data;
};
