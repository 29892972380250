import * as React from "react";
import { useState } from "react";
import {
  GridColDef,
  GridSortItem
} from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, IconButton, useMediaQuery } from "@mui/material";

import { COLORS } from "../../constants/colors";
import {
  ActionsMobile,
  Separator,
  StyledDataGrid,
  StyledPaper,
  StyledTableContainer,
  TableContainerMobile,
} from "./TableDataGrid.styles";
import CreateEditClientModal from "../modal/CreateEditClientModal";
import ConfirmModal from "../modal/ConfirmModal";
import { deleteClient, useClients } from "../../services/clients";
import { useMutation } from "react-query";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";

export interface IClient {
  id: number;
  petName: string;
  ownerName: string;
  phoneNumber: string;
  note?: string;
}

const sortTableByName: GridSortItem[] = [
  {
    field: "petName",
    sort: "asc",
  },
];

const ClientsTable = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMiniTablet = useMediaQuery("(max-width:1000px)");
  const { showNotification } = useNotificationContext();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isCreateEditClientModalOpen, setIsCreateEditClientModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState<IClient | null>(null);
  const defaultRowHeight = 55;
  const [deleteClientId, setDeleteClientId] = useState<number>(-1);

  const { data, refetch } = useClients();

  const { mutate: deleteClientMutation } = useMutation(deleteClient, {
    onSuccess: () => {
      setIsConfirmModalOpen(false);
      refetch();
      showNotification("Klijent uspesno obrisan.", Severity.Success);
    },
    onError: (error) => {
      showNotification("Greška prilikom brisanja klijenta", Severity.Error);
    },
  });

  const handleArrowClick = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  const getRowHeight = (params: any) => {
    return expandedRow === params.id ? "auto" : defaultRowHeight;
  };

  const closeClientModal = () => {
    setIsCreateEditClientModalOpen(false);
    refetch();
  };

  const openConfirmModal = (open: boolean, clientId?: number) => {
    open && clientId && setDeleteClientId(clientId);
    setIsConfirmModalOpen(open);
  };

  const handleEdit = (client: any) => {
    const formattedInfo: IClient = {
      id: client.id,
      petName: client.petName,
      ownerName: client.ownerName,
      phoneNumber: client.phoneNumber,
      note:client.note
    };

    setClientInfo(formattedInfo);
    setIsCreateEditClientModalOpen(true);
  };

  const columns: GridColDef[] = isMobile
    ? [
        {
          field: "petName",
          headerName: "",
          flex: 0.8,
          sortable: false,
          renderCell: (params) => {
            const isExpanded = expandedRow === params.row.id;
            return (
              <div style={{ marginTop: isExpanded ? "0.5rem" : undefined }}>
                <div>{params.value}</div>
                {isExpanded && (
                  <>
                    <TableContainerMobile>
                      <Separator />
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          Vlasnik:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.ownerName}
                        </Grid>
                        <Grid item xs={4}>
                          Broj telefona:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.phoneNumber}
                        </Grid>
                        <Grid item xs={4}>
                          Napomena:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.note}
                        </Grid>
                      </Grid>
                    </TableContainerMobile>
                  </>
                )}
              </div>
            );
          },
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 0.2,
          renderCell: ActionCellMobile,
        },
      ]
    : [
        {
          field: "petName",
          headerName: "Ime ljubimca",
          sortable: false,
          flex: 1,
        },
        {
            field: "ownerName",
            headerName: "Vlasnik",
            sortable: false,
            flex: 0.5,
        },
        {
          field: "phoneNumber",
          headerName: "Broj telefona",
          sortable: false,
          flex: 0.5,
        },
        {
          field: "note",
          headerName: "Napomena",
          sortable: false,
          flex: isMiniTablet ? 1 : 1.5,
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          renderCell: ActionCell,
          flex: 0.5,
        },
      ];

  function ActionCell(params) {
    return (
      <div>
        <IconButton
          style={{ color: COLORS.yellow_800 }}
          onClick={() => handleEdit(params.row)}
        >
          <BorderColorIcon />
        </IconButton>
        <IconButton
          style={{ color: COLORS.primary }}
          onClick={() => openConfirmModal(true, params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  function ActionCellMobile(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <ActionsMobile>
        {isExpanded && (
          <>
            <IconButton
              style={{ color: COLORS.yellow_800 }}
              onClick={() => handleEdit(params.row)}
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              style={{ color: COLORS.primary }}
              onClick={() => openConfirmModal(true, params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        <IconButton
          style={{
            color: COLORS.mediumGray,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </ActionsMobile>
    );
  }

  return (
    <StyledTableContainer>
      <StyledPaper>
        {data && (
          <StyledDataGrid
            justifyContent={isMobile ? "start" : "end"}
            rows={data}
            sortModel={sortTableByName}
            columns={columns}
            hideFooterPagination
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            hideFooterSelectedRowCount
            disableRowSelectionOnClick={true}
            columnHeaderHeight={isMobile ? 0 : 55}
            rowHeight={defaultRowHeight}
            getRowHeight={getRowHeight}
            getRowClassName={(params) =>
              expandedRow === params.id ? "expanded-row" : ""
            }
            style={{ width: "100%" }}
          />
        )}
      </StyledPaper>
      {isCreateEditClientModalOpen && (
        <CreateEditClientModal
          isModalOpen={isCreateEditClientModalOpen}
          handleClose={() => closeClientModal()}
          client={clientInfo ?? undefined}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          isModalOpen={isConfirmModalOpen}
          handleClose={() => openConfirmModal(false)}
          onSave={() => deleteClientMutation(deleteClientId)}
          actionToConfirm="želite da obrišete"
        />
      )}
    </StyledTableContainer>
  );  
};
export default ClientsTable;
