export const CALENDAR = "/calendar";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgotPassword"
export const FORGOT_PASSWORD_VERIFICATION = "/forgotPassword/:email/:verificationCode"
export const REGISTRATION = "/register";
export const REGISTRATION_VERIFICATION =
  "/registration/:email/:verificationCode/:isWorker";
export const SUCCESS = "/success";
export const ONBOARD = "/onboard";
export const STEP_1 = `${ONBOARD}/generalInfo`;
export const STEP_2 = `${ONBOARD}/workingHours`;
export const STEP_3 = `${ONBOARD}/galery`;
export const STEP_4 = `${ONBOARD}/services`;

export const STAFF = "/staff";
export const SERVICES = "/services";
export const CLIENTS ="/clients";
export const PROFILE_SALON = "/salon-profile";
export const DAYS_OFF = "/daysOff";
export const PRIVACY_POLICY = "/privacyPolicy";
export const ANALYTICS = "/analytics";
export const SUPPORT = "/support";
export const HELP = "/help";
