/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import UniversalModal from "../../modal/UniversalModal";
import {
  StyledAutocomplete,
  StyledErrorMessage,
  StyledFormButtonsWrapper,
  StyledFormHeader,
  StyledFormHeading,
  StyledFormModal,
  StyledLoadingContainer,
  StyledSelect,
  StyledTimeSlotText,
  StyledXButton,
  SearchButtonContainer, 
  SearchContainer, 
  SearchResultItem, 
  SearchResultsContainer, 
  SearchTitle
} from "./ReservationModal.styles";
import { StyledCalendarFullButton } from "../Calendar.styles";
import { ReactComponent as Xicon } from "../../../assets/X-ico.svg";
import { useServices } from "../../../services/services";
import {
  editAppointment,
  postNewappointment,
} from "../../../services/appointments/appointments";
import { useSalonFreeSlots } from "../../../services/availableTimeSlots/availableTimeSlots";
import { StyledDatePicker } from "../../dateTimePicker/DateTimePicker.styles";
import { StyledMenuItem } from "../../dropdown/Dropdown.styles";
import { Appointment } from "../../../types/Types";
import {
  Severity,
  useNotificationContext,
} from "../../../context/NotificationContext";
import { InputField } from "../../inputs/TextField";
import { PrimaryButton } from "../../buttons/primaryButton/PrimaryButton";
import { searchClients } from "../../../services/clients";
import { IClient } from "../../dataGridTable/ClientsTable";

const defaultFormErrors = {
  ownerNameError: false,
  serviceError: false,
  timeError: false,
  timeErrorMessage: "",
  petNameError: false,
  mobilePhoneError: false
};

interface Service {
  id: number;
  label: string;
  serviceGroupId: number;
  serviceGroupName: string;
}

interface ReservationModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  handleCloseEvent?: () => void;
  editData?: any;
}

const ReservationModal = ({
  modalIsOpen,
  setModalIsOpen,
  handleClose,
  editData,
  handleCloseEvent,
}: ReservationModalProps) => {
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [freeTimeSlots, setFreeTimeSlots] = useState<any[]>([]);
  const [serviceData, setServiceData] = useState<Service[]>([]);
  const { data, isFetching } = useServices();
  const { showNotification } = useNotificationContext();

  const defaultFormData = {
    date: selectedDate.format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
    ownerName: "",
    petName: "",
    mobilePhone: "",
    note: "",
    serviceIds: null,
    timeData: {
      workerId: 0,
      timeSlot: "default",
    },
  };
  const [newFormData, setNewFormData] = useState<Appointment>(defaultFormData);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [clientSearchResults, setClientSearchResults] = useState<IClient[]>([]);
  const [clientSelected, setClientSelected] = useState<boolean>(false);

  const fetchClients = async (query: string) => {
    try {
      const response = await searchClients({ searchText: query });
      setClientSearchResults(response.result);
    } catch (error) {
      console.error("Greška prilikom pretrage klijenata:", error);
    }
  };

  useEffect(() => {
    const query = searchQuery || "";
    if (query.trim().length >= 3) {
      fetchClients(query.trim());
    } else {
      setClientSearchResults([]);
    }
  }, [searchQuery]);
  
  const handleClientSelect = (client: IClient) => {
    setNewFormData((prev) => ({
      ...prev,
      ownerName: client.ownerName || "",
      petName: client.petName || "",
      mobilePhone: client.phoneNumber || "",
      note: client.note || "",
    }));
    setClientSelected(true);
  };

  const handleSkipClient = () => {
    setClientSelected(true);
  };

  useEffect(() => {
    if (editData) {
      setNewFormData({
        id: editData.appointmentId,
        date: dayjs(editData?.start).format("YYYY-MM-DD"),
        ownerName: editData.ownerName || "",
        petName: editData.petName || "",
        mobilePhone: editData.mobilePhone || "",
        note: editData.note || "",
        serviceIds: null,
        timeData: {
          workerId: 0,
          timeSlot: "default",
        },
      });

      setSelectedDate(dayjs(editData?.start));
    } else {
      setNewFormData(defaultFormData);
    }
  }, [editData]);

  useEffect(() => {
    if (data && !isFetching) {
      const newArray = data.map((service) => ({
        label: service.value,
        id: service.key,
        ...service
      }));
      setServiceData(newArray);
    }

    if (data?.length > 0 && !isFetching) {
      setNewFormData((prev) => ({
        ...prev,
        serviceIds: editData
          ? data
              .filter((item) =>
                editData?.services.some(
                  (service) => service.serviceName === item.value
                )
              )
              .map((item) => item.key)
          : null,
      }));
    }
  }, [data, isFetching]);

  const { mutate: postNewAppointmentMutation } = useMutation(
    postNewappointment,
    {
      onSuccess: (data) => {
        setNewFormData(defaultFormData);
        handleClose();
        showNotification("Rezervacija uspesno dodata", Severity.Success);
      },
      onError: (error) => {
        showNotification(
          "Greška prilikom postavljanja rezervacije",
          Severity.Error
        );
      },
    }
  );

  const { mutate: editManuelReservation } = useMutation(editAppointment, {
    onSuccess: (data) => {
      handleCloseEvent && handleCloseEvent();
      handleClose();
      showNotification("Rezervacija uspesno izmenjena", Severity.Success);
    },
    onError: (error) => {
      showNotification("Greška prilikom menjanja rezervacije", Severity.Error);
    },
  });

  let selectedDateDash = dayjs(selectedDate).format("YYYY-MM-DD").toString();
  let selectedDateSlash = dayjs(selectedDate).format("MM/DD/YYYY").toString();

  const {
    data: freeSlotsData,
    isLoading,
    refetch: refetchSlots,
  } = useSalonFreeSlots(
    selectedDateDash,
    newFormData.serviceIds,
    editData && editData.appointmentId
  );

  useEffect(() => {
    setFreeTimeSlots(freeSlotsData?.result?.[selectedDateSlash]);
  }, [freeSlotsData]);

  const handleDateChange = (field, e) => {
    setNewFormData((prev) => ({
      ...prev,
      date: e.format("YYYY-MM-DD"),
    }));
    setSelectedDate(e);
    if (newFormData.serviceIds && newFormData.serviceIds.length) {
      refetchSlots();
    }
  };

  const handleServiceChange = (e, value: any) => {
    setNewFormData((prev) => ({
      ...prev,
      serviceIds: value.map((i) => i.id),
    }));
    if (newFormData.serviceIds && newFormData.serviceIds.length) {
      refetchSlots();
    }
  };

  const handleChange = (field: string, value: any) => {
    if (field === "startTime") {
      const timeDataValue = JSON.parse(value);
      setNewFormData((prev) => ({
        ...prev,
        timeData: {
          workerId: timeDataValue.workerId,
          timeSlot: timeDataValue.timeSlot,
        },
      }));
      setFormErrors((prev) => ({
        ...prev,
        timeError: false,
        timeErrorMessage: "",
      }));
    } else {
      setFormErrors((prev) => ({ ...prev, [field]: false }));
      setNewFormData((prev) => ({ ...prev, [field]: value }));
    }
  
    if (field === "ownerName") {
      setNewFormData((prev) => ({
        ...prev,
        ownerName: value,
      }));
    }
  };
  

  const validateForm = () => {
    const newErrors = {
      ownerNameError: false,
      serviceError: false,
      timeError: false,
      timeErrorMessage: "",
      petNameError: false,
      mobilePhoneError: false
    };

    if (!newFormData.ownerName.trim()) {
      newErrors.ownerNameError = true;
    }

    if (!newFormData.serviceIds || !newFormData.serviceIds.length) {
      newErrors.serviceError = true;
    }

    if (newFormData.timeData.timeSlot === "default") {
      newErrors.timeError = true;
      newErrors.timeErrorMessage = "Molimo vas odaberite termin";
    } else if (
      newFormData.date === dayjs().format("YYYY-MM-DD") &&
      dayjs().hour() >= Number(newFormData.timeData.timeSlot.slice(0, 2)) &&
      dayjs().minute() > Number(newFormData.timeData.timeSlot.slice(3, 5))
    ) {
      newErrors.timeError = true;
      newErrors.timeErrorMessage = "Termin ne moze biti u proslosti";
    }

    if (!newFormData.serviceIds) {
      newErrors.serviceError = true;
    }

    if (!newFormData.petName.trim()) {
      newErrors.petNameError = true;
    }

    if (!newFormData.mobilePhone.trim()) {
      newErrors.mobilePhoneError = true;
    }

    setFormErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.values(errors).every((error) => !error)) {
      if (editData) {
        editManuelReservation(newFormData);
      } else {
        postNewAppointmentMutation(newFormData);
      }
    }
  };

  const closeModal = () => {
    handleCloseEvent && handleCloseEvent();
    setModalIsOpen(false);
    setNewFormData({
      ...defaultFormData,
      date: selectedDate.format("YYYY-MM-DD"),
    });
    setFormErrors(defaultFormErrors);
    setSearchQuery("");
    setClientSearchResults([]);
    setClientSelected(false);
  };

  const resolveDefaultTime = (
    selectedDate: Date,
    freeTimeSlots: Array<{ workerId: number; timeSlot: string }>
  ) => {
    if (!selectedDate || !freeTimeSlots || freeTimeSlots.length === 0) {
      return { workerId: 0, timeSlot: "default" };
    }
    const time = selectedDate.toTimeString().split(" ")[0]; // e.g., "11:00:00"
    const matchedSlot = freeTimeSlots.find((slot) => slot.timeSlot === time);
    return matchedSlot || { workerId: 0, timeSlot: "default" };
  };

  const resolveDefault = () => {
    const selectedServices = editData?.services?.map(
      (service) => service.serviceId
    );
    const indexes = selectedServices
      ?.map((element) => serviceData?.findIndex((data) => element === data.id))
      .filter((index) => index !== -1); // Remove invalid indexes
    const defaultValue = indexes?.map((index) => serviceData[index]);
    return defaultValue;
  };
  useEffect(() => {
    if (
      selectedDate &&
      newFormData.serviceIds &&
      newFormData?.serviceIds[0] !== null
    ) {
      refetchSlots();
    }
  }, [newFormData.serviceIds]);

  return (
    <UniversalModal
      isModalOpen={modalIsOpen}
      handleClose={closeModal}
      width={
        window.innerWidth >= 900
          ? "34.375rem"
          : window.innerWidth >= 600
          ? "65%"
          : "90%"
      }
    >
      <StyledFormModal>
        <StyledFormHeader>
          <StyledFormHeading>Ručno Zakazivanje</StyledFormHeading>
          <StyledXButton onClick={closeModal}>
            <Xicon />
          </StyledXButton>
        </StyledFormHeader>
        {!clientSelected && !editData ? (
            <SearchContainer>
            <SearchTitle>Klijent već postoji?</SearchTitle>
            <TextField
              label="Pretraži klijente (ime, ljubimac ili telefon)"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value || "")}
              fullWidth
            />
            {clientSearchResults.length > 0 && (
              <SearchResultsContainer>
                {clientSearchResults.map((client) => (
                  <SearchResultItem
                    key={client.id}
                    onClick={() => handleClientSelect(client)}
                  >
                    {client.petName} - {client.ownerName} - {client.phoneNumber}
                  </SearchResultItem>
                ))}
              </SearchResultsContainer>
            )}
            <SearchButtonContainer>
              <PrimaryButton text="Preskoči" onClick={handleSkipClient} />
            </SearchButtonContainer>
          </SearchContainer>
        ) : (
        <form onSubmit={handleSubmit}>
          <InputField
            label={formErrors.ownerNameError ? "Molim vas unesite ime" : "Ime vlasnika"}
            value={newFormData.ownerName}
            onChange={(e) => handleChange("ownerName", e.target.value)}
            error={formErrors.ownerNameError}
          />
          <StyledDatePicker
            value={selectedDate}
            minDate={dayjs()}
            onChange={(e) => handleDateChange("date", e)}
          />
          {serviceData?.length > 0 && (
            <StyledAutocomplete
              multiple
              options={serviceData || []}
              disablePortal
              groupBy={(option: any) => option.serviceGroupName }
              value={
                serviceData?.filter((option) =>
                  newFormData.serviceIds?.includes(option.id)
                ) || []
              }
              onChange={(e: any, newValue) => handleServiceChange(e, newValue)}
              renderGroup={(params) => {
                return (
                  <li>
                    <strong>{params.group}</strong> {/* Group label */}
                    <ul>
                      {/* Iterate over the children of this group */}
                      {params.children}
                    </ul>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    formErrors.serviceError
                      ? "Molim vas odaberite uslugu"
                      : "Odaberite uslugu"
                  }
                  error={formErrors.serviceError}
                />
              )}
            />
          )}
          {isLoading ? (
            <StyledLoadingContainer>Loading...</StyledLoadingContainer>
          ) : (
            <StyledSelect
              value={
                formErrors.timeError
                  ? JSON.stringify({ workerId: 0, timeSlot: "default" })
                  : JSON.stringify(newFormData.timeData) ||
                    (editData &&
                      JSON.stringify(resolveDefaultTime(editData?.start, freeTimeSlots)))
              }
              onChange={(e) => handleChange("startTime", e.target.value)}
            >
              {freeTimeSlots?.length && (
                <StyledMenuItem
                  value={JSON.stringify({ workerId: 0, timeSlot: "default" })}
                  disabled
                >
                    <StyledTimeSlotText
                      style={{ color: formErrors.timeError ? "#d32f2f" : "inherit" }}
                    >
                      {formErrors.timeError
                        ? formErrors.timeErrorMessage
                        : "Odaberite Termin"}
                    </StyledTimeSlotText>
                </StyledMenuItem>
              )}
              {freeTimeSlots?.length ? (
                freeTimeSlots?.map((slot) => (
                  <StyledMenuItem
                    key={slot?.timeSlot}
                    value={JSON.stringify(slot)}
                  >
                    <StyledTimeSlotText>{slot?.timeSlot}</StyledTimeSlotText>
                  </StyledMenuItem>
                ))
              ) : (
                <StyledMenuItem
                  value={JSON.stringify({ workerId: 0, timeSlot: "default" })}
                  disabled
                >
                  <StyledTimeSlotText
                    style={{ color: formErrors.timeError ? "#d32f2f" : "inherit" }}
                  >
                    {formErrors.timeError
                      ? formErrors.timeErrorMessage
                      : "Nema slobodnih termina"}
                  </StyledTimeSlotText>
                </StyledMenuItem>
              )}
            </StyledSelect>
            )}
          <InputField
            label={formErrors.petNameError ? "Molim vas unesite ime ljubimca" : "Ime ljubimca"}
            value={newFormData.petName}
            onChange={(e) => handleChange("petName", e.target.value)}
            error={formErrors.petNameError}
          />
          <InputField
            label = {formErrors.mobilePhoneError ? "Molim vas unesite broj telefona" : "Telefon"}
            value={newFormData.mobilePhone}
            onChange={(e) =>
              handleChange("mobilePhone", e.target.value)
            }
            error={formErrors.mobilePhoneError}
          />
          <InputField
            label="Napomena"
            multiline
            minRows={window.innerWidth <= 600 ? 3 : 4}
            value={newFormData.note}
            onChange={(e) => handleChange("note", e.target.value)}
          />
          <StyledFormButtonsWrapper>
            {editData ? (
              <StyledCalendarFullButton type="submit">
                Izmeni
              </StyledCalendarFullButton>
            ) : (
              <StyledCalendarFullButton type="submit">
                Sačuvaj
              </StyledCalendarFullButton>
            )}
          </StyledFormButtonsWrapper>
        </form>
        )}
      </StyledFormModal>
    </UniversalModal>
  );
};

export default ReservationModal;
