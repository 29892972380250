import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { login } from "../../services/auth";
import PetifyLogo from "../../assets/svg/PetifyLogo";
import { InputField } from "../../components/inputs/TextField";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import { LinkButton } from "../../components/buttons/linkButton/LinkButton";
import {
  Header,
  StyledCenteredContainer,
  StyledMasterContainer,
  StyledSupportButton,
} from "../../constants/customStyles";
import isEmail from "../../helpers/inputValidation/validators/isEmail";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import { InputError } from "../../helpers/inputValidation/InputError";
import and from "../../helpers/inputValidation/validators/and";
import allValid from "../../helpers/inputValidation/validators/allValid";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  IS_ONBOARDED,
  IS_WORKER,
} from "../../constants/localStorageKeys";
import { COLORS } from "../../constants/colors";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import { StyledIconButton, StyledLoginFieldsDiv } from "./Login.styles";
import {
  SupportAgentRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { HELP, SUPPORT } from "../../constants/urlPaths";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<InputError | null>(null);
  const [passwordError, setPasswordError] = useState<InputError | null>(null);
  const { showNotification } = useNotificationContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { mutate: loginUserMutation } = useMutation(login, {
    onSuccess: (data) => {
      localStorage.setItem(IS_ONBOARDED, data?.result?.isOnBoarded);
      localStorage.setItem(ACCESS_TOKEN, data?.result?.accessToken);
      localStorage.setItem(REFRESH_TOKEN, data?.result?.refreshToken);
      localStorage.setItem(IS_WORKER, data?.result?.isWorker);
      showNotification("Uspesno ste ulogovani.", Severity.Success);
      navigate("/");
    },
    onError: (error) => {
      showNotification("Nepostojeci korisnik", Severity.Error);
    },
  });

  const validateFormFields = (): boolean => {
    const validated = {
      email: and(isFilledString(email, InputError.EmptyEmail), () =>
        isEmail(email, InputError.InvalidEmail)
      ),
      password: isFilledString(password, InputError.EmptyLoginPassword),
    };
    if (!allValid(validated)) {
      setEmailError(validated.email.isValid ? null : validated.email.error);
      setPasswordError(
        validated.password.isValid ? null : validated.password.error
      );
      return false;
    }
    setEmailError(null);
    setPasswordError(null);
    return true;
  };

  const handleLogin = () => {
    if (validateFormFields()) {
      loginUserMutation({ email, password });
    }
  };

  return (
    <StyledMasterContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <StyledCenteredContainer>
        <PetifyLogo />
        <Header>Prijava!</Header>
        <div
          style={{
            display: "inline-block",
            alignItems: "center",
            flexDirection: "column",
            color: "#6D6D6D",
            fontSize: "14px",
            fontWeight: "400",
            marginBottom: "0.93rem",
            marginTop: "0.8rem",
            textAlign: "justify",
          }}
        >
          <span>Ukoliko već imate registrovan salon, prijavite se</span>
          <span> pomoću email adrese i lozinke.</span>
        </div>
        <StyledLoginFieldsDiv>
          <InputField
            placeholder="email"
            label={Boolean(emailError) ? emailError : "email"}
            type={"text"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(emailError)}
            onBlur={() => validateFormFields()}
            onFocus={() => setEmailError(null)}
          />
          <InputField
            placeholder="password"
            label={Boolean(passwordError) ? passwordError : "password"}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            error={Boolean(passwordError)}
            onBlur={() => validateFormFields()}
            onFocus={() => setPasswordError(null)}
            InputProps={{
              endAdornment: (
                <StyledIconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </StyledIconButton>
              ),
            }}
          />
          <PrimaryButton
            text={"Prijavi se"}
            width={"100%"}
            onClick={handleLogin}
          />
        </StyledLoginFieldsDiv>
        <div style={{ marginTop: "40px" }}>
          <LinkButton
            text={"Zaboravili ste šifru?"}
            onClick={() => navigate("/forgotPassword")}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <span style={{ color: COLORS.mediumGray, fontSize: "1.rem" }}>
            Već imate nalog?
          </span>
          <LinkButton
            text={"Registrujte se"}
            onClick={() => navigate("/register")}
          />
        </div>
      </StyledCenteredContainer>
    </StyledMasterContainer>
  );
};
