import {
  Autocomplete,
  Box,
  Button,
  Input,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { COLORS } from "../../../constants/colors";

export const StyledFormModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.938rem",
  width: "100%",
  maxHeight: "91vh",


  "&::-webkit-scrollbar": {
    width: "0.4rem",
  },

  "& form": {
    display: "flex",
    flexDirection: "column",
    gap: "0.938rem",
    width: "100%",
  },

  [theme.breakpoints.down("md")]: {
    maxHeight: "90vh",
    height: "fit-content",
    overflowY: "auto",
  },
}));

export const StyledFormHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledXButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: "0.2rem",

  "&:hover": {
    backgroundColor: "transparent",

    "& > svg > g > path": {
      transition: "250ms ease-in-out",
      fill: COLORS.medium_gray,
    },
  },

  [theme.breakpoints.down("md")]: {
    width: "1.55rem",
  },
}));

export const StyledFormHeading = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  padding: "0.37rem 0",
  color: COLORS.darkGray,

  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
  },
}));

export const StyledErrorMessage = styled(Typography)(({ theme }) => ({
  color: COLORS.error_700,
  fontSize: "1rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

export const StyledTextInput = styled(Input)(({ theme }) => ({
  backgroundColor: COLORS.lightGray,
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  padding: "1rem 1.38rem",
  borderRadius: "0.5625rem",

  "&::before": {
    border: "none !important",
  },

  "&::after": {
    border: "none",
  },

  "& input::placeholder": {
    color: COLORS.mediumGray,
    opacity: 1,
  },

  [theme.breakpoints.down("md")]: {
    padding: "0.6rem 0.8rem",
    fontSize: "0.9rem",
  },
}));

export const StyledLoadingContainer = styled(Box)((theme) => ({
  backgroundColor: COLORS.lightGray,
  color: COLORS.mediumGray,
  textAlign: "center",
  padding: "1rem 0",
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: COLORS.lightGray,
  color: COLORS.mediumGray,
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  borderRadius: "0.5625rem",
  border: "none",

   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "16.5px 14px",
  },

  "& fieldset.MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },

  "& .MuiMenu-paper ul": {
    backgroundColor: "red",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
}));

export const StyledTimeSlotText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  padding: "0",
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: COLORS.lightGray,
  color: COLORS.mediumGray,
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  borderRadius: "0.5625rem",

  "& .MuiSelect-select": {
    padding: 0,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },

  "& svg": {
    right: "3%",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
}));

export const StyledFormButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.938rem",
  width: "100%",
  marginTop: "13px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",

    "& button": {
      width: "100%",
      minWidth: "0",
      height: "3.438rem",
    },
  },
}));

export const SearchContainer = styled("div")({
  padding: "1rem",
});

export const SearchTitle = styled("div")({
  textAlign: "center",
  marginBottom: "0.5rem",
  fontWeight: "normal",
  fontSize: "1.1rem",
});

export const SearchResultsContainer = styled("div")({
  marginTop: "0.5rem",
  maxHeight: "200px",
  overflowY: "auto",
  border: "1px solid #ccc",
  borderRadius: "4px",
});

export const SearchResultItem = styled("div")({
  padding: "0.5rem",
  borderBottom: "1px solid #eee",
  cursor: "pointer",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
});

export const SearchButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",
});