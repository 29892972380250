import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";

import { PageContainer } from "../pageContainer/PageContainer";
import { HeaderPage } from "../../constants/customStyles";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import { HeaderContainer } from "./ClientsPage.styles";
import LoaderWithLogo from "../../components/loader/loader";
import { useClients } from "../../services/clients";
import CreateEditClientModal from "../../components/modal/CreateEditClientModal";
import ClientsTable from "../../components/dataGridTable/ClientsTable";

const Clients = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { refetch, isLoading } = useClients();

  const [isCreateEditClientModalOpen, setIsCreateEditClientModalOpen] =
    useState(false);

  const openClientModal = () => {
    setIsCreateEditClientModalOpen(true);
  };

  const closeClientModal = () => {
    refetch();
    setIsCreateEditClientModalOpen(false);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoaderWithLogo></LoaderWithLogo>
      ) : (
        <>
          <HeaderContainer>
            <HeaderPage>Klijenti</HeaderPage>
            <PrimaryButton
              width={isMobile ? 125 : 255}
              text="Dodaj novog klijenta"
              onClick={openClientModal}
              fontSize={isMobile ? "0.75rem" : "1rem"}
            />
          </HeaderContainer>
          <div style={{ height: "2rem", width: "100%" }}/>
          <ClientsTable/>
          {isCreateEditClientModalOpen && (
            <CreateEditClientModal
              isModalOpen={isCreateEditClientModalOpen}
              handleClose={() => closeClientModal()}
              client={undefined}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Clients;